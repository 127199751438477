import React, {
  useCallback, useContext, useEffect, useRef, useState,
} from "react";

import { ApolloError, useMutation } from "@apollo/client";
import {
  sendCode,
  sendCodeVariables,
} from "@layout/modals/graphql/__generated__/sendCode";
import { SEND_CODE } from "@layout/modals/graphql/SEND_CODE";
import { FormTypeEnum, LoginAcceptForm, LoginTypeEnum } from "@layout/modals/types";
import {
  convertToI18lClearPhone,
  getErrorByCode,
  setAuthParamToURL,
} from "@layout/modals/utils";
import { useLocation } from "@reach/router";
import { navigate } from "gatsby";

import DigitCodeInput from "@/components/common/Inputs/digitCodeInput";
import { getInitiateCodeState, isCodeLengthValid } from "@/components/common/Inputs/digitCodeInput/utils";
import { getReferralKeyFromCookies, removeReferralKeyFromCookies } from "@/components/layout/Template/utils";
import AuthorizationFormContext from "@/contexts/AuthorizationForm/AuthorizationFormContext";
import GlobalContext from "@/contexts/Global/GlobalContext";
import { GlobalContextType } from "@/contexts/Global/types";
import UserContext from "@/contexts/User/UserContext";
import useAuthorize from "@/hooks/useAuthorize";
import { authorizeVariables } from "@/hooks/useAuthorize/graphql/__generated__/authorize";
import { useManageUtmData } from "@/hooks/useManageUtmData";
import { getErrorCode } from "@/utils/apolloUtils";
import Timer from "@components/common/Timer";
import { SEND_OTP_CODE_RETRY_DELAY } from "@components/constants";

import "./styles.scss";

const FormWithCodeModal = ({ contactValue }: LoginAcceptForm) => {
  const location = useLocation();
  const {
    isExpert,
  } = useContext<GlobalContextType>(GlobalContext);
  const firstCodeInputRef = useRef<HTMLInputElement>(null);

  if (!contactValue) {
    // if there is no passed prop for authorization, we transfer it to the authorization form
    setAuthParamToURL(location, FormTypeEnum.Login);
  }

  const {
    promoUnauthorizetUser,
  } = useContext(UserContext);
  const {
    loginType,
    sentCodeTimestamp,
    setSentCodeTimestamp,
  } = useContext(AuthorizationFormContext);

  const [sendCodeMutation, { loading: codeLoading }] = useMutation<sendCode, sendCodeVariables>(SEND_CODE, {
    fetchPolicy: "network-only",
  });

  const [code, setCode] = useState<string>(getInitiateCodeState());
  const [errorCode, setErrorCode] = useState<number | null>(null);
  const [isUpdateCodeLinkShown, setIsUpdateCodeLinkShown] = useState(false);

  const onAuthorizeSuccess = useCallback(() => {
    setErrorCode(null);
    removeReferralKeyFromCookies();

    if (!isExpert && promoUnauthorizetUser) {
      navigate("/profile/promo-codes");
    }
  }, [isExpert, promoUnauthorizetUser]);

  const onAuthorizeError = useCallback((authError: ApolloError) => {
    setCode(getInitiateCodeState());
    firstCodeInputRef.current?.focus();
    setErrorCode(getErrorCode(authError));
  }, []);

  const {
    authorize,
    loading: authLoading,
  } = useAuthorize(onAuthorizeSuccess, onAuthorizeError);

  const { getUtmData } = useManageUtmData();

  const checkTimer = (value: number) => {
    if (!isUpdateCodeLinkShown && value >= 0) {
      setIsUpdateCodeLinkShown(true);
    }
  };

  function retrySendCode() {
    switch (loginType) {
      case LoginTypeEnum.LoginByEmail:
        sendCodeMutation({
          variables: {
            email: contactValue,
            utm: getUtmData(),
          },
        });
        break;
      case LoginTypeEnum.LoginByPhone:
      default:
        sendCodeMutation({
          variables: {
            phone: convertToI18lClearPhone(contactValue),
            utm: getUtmData(),
          },
        });
        break;
    }

    setIsUpdateCodeLinkShown(false);
    setSentCodeTimestamp(Date.now() + SEND_OTP_CODE_RETRY_DELAY);
  }

  useEffect(() => {
    firstCodeInputRef.current?.focus();
  }, []);

  useEffect(() => {
    if (isCodeLengthValid(code)) {
      const loginInfo: authorizeVariables = { code };

      switch (loginType) {
        case LoginTypeEnum.LoginByEmail:
          loginInfo.email = contactValue;
          break;
        case LoginTypeEnum.LoginByPhone:
          loginInfo.phone = convertToI18lClearPhone(contactValue);
          break;
        default:
          break;
      }

      loginInfo.utm = getUtmData();
      loginInfo.referral = getReferralKeyFromCookies();

      authorize(loginInfo);
    }
  }, [authorize, code, contactValue, getUtmData, loginType]);

  return (
    <div className="form-with-code-modal">
      <DigitCodeInput
        code={code}
        setCode={setCode}
        disabled={authLoading || codeLoading}
        firstCodeInputRef={firstCodeInputRef}
      />

      {errorCode && (
      <div className="form-with-code-modal__error">
        {getErrorByCode(errorCode, loginType === LoginTypeEnum.LoginByEmail ? "письмо" : "СМС")}
      </div>
      )}
      <div className="auth__text">
        {loginType === LoginTypeEnum.LoginByEmail
          ? (
            <>
              {`Код отправлен на почту ${contactValue}.`}
              <br />
              Проверьте на всякий случай папку «Спам»
            </>
          )
          : `Код отправлен на ${contactValue}`}
      </div>
      <div className="auth__text">
        {isUpdateCodeLinkShown ? (
          <b onClick={retrySendCode}>Получить новый код</b>
        ) : (
          <>
            Получить новый код можно через&nbsp;
            <Timer timestamp={sentCodeTimestamp || undefined} valueCallback={checkTimer} />
          </>
        )}
      </div>
      <div className="form-with-code-modal__link a">
        <span onClick={() =>
          setAuthParamToURL(location, FormTypeEnum.Troubles)}
        >
          У меня проблемы со входом
        </span>
      </div>
    </div>
  );
};

export default FormWithCodeModal;
