import * as React from "react";

import "./styles.scss";

import { Icon, IconTypeEnum } from "../icon";

import { ButtonColorEnum, ButtonIconPositionEnum, ButtonProps, ButtonSizeEnum } from "./types";
import { getButtonClassName, getIconSize, getLoadingIconSize } from "./utils";

export const Button = (props: ButtonProps) => {
  const {
    text,
    subtext,
    size = ButtonSizeEnum.Large,
    iconSize,
    color = ButtonColorEnum.Dark,
    icon,
    iconPosition,
    className,
    isLoading = false,
    disabled = false,
    appearsAsLink = false,
    children,
    iconViewBox,
    ...rest
  } = props;

  const compoundClassName = getButtonClassName(size, color, appearsAsLink, className);

  const calculatedIconSize = getIconSize(iconSize ?? size);
  const loadingIconSize = getLoadingIconSize(size);

  return (
    <button className={compoundClassName} disabled={isLoading || disabled} {...rest}>
      {!isLoading && (
        <>
          <div className="button__text">
            {icon && iconPosition === ButtonIconPositionEnum.Left && (
              <Icon className="icon-left" type={icon} size={calculatedIconSize} viewBox={iconViewBox} />
            )}
            <span>{text}</span>
            {icon && iconPosition === ButtonIconPositionEnum.Right && (
              <Icon className="icon-right" type={icon} size={calculatedIconSize} viewBox={iconViewBox} />
            )}
          </div>
          {subtext && <div className="button__subtext">{subtext}</div>}
        </>
      )}
      {isLoading && (
        <div className="button__loading">
          <Icon type={IconTypeEnum.Loading} size={loadingIconSize} viewBox={iconViewBox} />
        </div>
      )}
      {children}
    </button>
  );
};

export { ButtonSizeEnum, ButtonColorEnum, ButtonIconPositionEnum };
