import { IconTypeEnum } from "../icon";

export enum ButtonSizeEnum {
  Large = "Large",
  Middle = "Middle",
  Small = "Small",
  XSmall = "XSmall",
}

export enum ButtonColorEnum {
  Dark = "Dark",
  Light = "Light",
  White = "White",
  Black = "Black",
  Red = "Red",
}

export enum ButtonIconPositionEnum {
  Left = "Left",
  Right = "Right",
}

export type ButtonProps = {
  text: string;
  subtext?: string | null | JSX.Element;
  size?: ButtonSizeEnum;
  iconSize?: ButtonSizeEnum
  color?: ButtonColorEnum;
  icon?: IconTypeEnum;
  iconPosition?: ButtonIconPositionEnum;
  isLoading?: boolean;
  disabled?: boolean;
  appearsAsLink?: boolean;
  className?: string;
  [x: string]: any;
};
